<template>
  <component-frame title="Forms">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li>
            <a href="#" v-scroll-to="'#stacked'">Form with stacked labels</a>
          </li>
          <li>
            <a href="#" v-scroll-to="'#inline'">Form with inline labels</a>
          </li>
          <li><a href="#" v-scroll-to="'#sizes'">Form field sizes</a></li>
          <li>
            <a href="#" v-scroll-to="'#radioGroup'">Radio Button Group</a>
          </li>
          <li>
            <a href="#" v-scroll-to="'#textWithAction'">Text field with action</a>
          </li>
          <li><a href="#" v-scroll-to="'#stepper'">Step Input</a></li>
          <li><a href="#" v-scroll-to="'#upload'">File Upload</a></li>
          <li>
            <a href="#" v-scroll-to="'#dragDrop'">File Upload - Drag and Drop</a>
          </li>
          <li><a href="#" v-scroll-to="'#datepicker'">Date Picker</a></li>
          <li>
            <a href="#" v-scroll-to="'#search-category'">Categorized Search</a>
          </li>
          <li><a href="#" v-scroll-to="'#switch'">Switch</a></li>
          <li><a href="#" v-scroll-to="'#states'">Form states</a></li>
        </ul>
      </div>
    </div>

    <h3 id="stacked">Form with stacked labels</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <form action="">
            <div class="form-row">
              <div class="form-group col-lg-6">
                <label for="firstName">First Name</label>
                <input class="form-control" type="text" name="firstName" id="firstName" autocorrect="off" autocomplete="given-name" autofocus />
              </div>
              <div class="form-group col-lg-6">
                <label for="lastName">Last Name</label>
                <input class="form-control" type="text" name="lastName" id="lastName" autocomplete="family-name" />
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email" />
            </div>
            <div class="form-group">
              <label for="phone">Phone Number</label>
              <input class="form-control" type="tel" name="phone" id="phone" autocomplete="tel-national" />
            </div>
            <div class="form-group">
              <label for="address">Address</label>
              <input class="form-control" type="text" name="address" id="address" autocorrect="off" autocomplete="shipping street-address" />
            </div>
            <div class="form-group">
              <label for="frmCityS">City</label>
              <input class="form-control" name="ship-city" id="frmCityS" autocomplete="shipping locality" />
            </div>
            <div class="form-row">
              <div class="form-group col-lg-8">
                <label for="frmStateS">Province</label>
                <select class="form-control" name="state" id="state">
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NT">Northwest Territory</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon Territory</option>
                </select>
              </div>
              <div class="form-group col-lg-4">
                <label for="frmZipS">Postal Code</label>
                <input class="form-control" name="ship-zip" id="frmZipS" placeholder="10011" autocomplete="shipping postal-code" />
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary">
                Do something
              </button>
            </div>
          </form>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;form action=""&gt;
  &lt;div class="form-row"&gt;
    &lt;div class="form-group col-lg-6"&gt;
      &lt;label for="firstName"&gt;First Name&lt;/label&gt;
      &lt;input class="form-control" type="text" name="firstName" id="firstName" autocorrect="off" autocomplete="given-name" autofocus&gt;
    &lt;/div&gt;
    &lt;div class="form-group col-lg-6"&gt;
      &lt;label for="lastName"&gt;Last Name&lt;/label&gt;
      &lt;input class="form-control" type="text" name="lastName" id="lastName" autocomplete="family-name"&gt;
    &lt;/div&gt;
  &lt;/div&gt;
  &lt;div class="form-group"&gt;
    &lt;label for="email"&gt;Email&lt;/label&gt;
    &lt;input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email"&gt;
  &lt;/div&gt;
  &lt;div class="form-group"&gt;
    &lt;label for="phone"&gt;Phone Number&lt;/label&gt;
    &lt;input class="form-control" type="tel" name="phone" id="phone" autocomplete="tel-national"&gt;
  &lt;/div&gt;
  &lt;div class="form-group"&gt;
    &lt;label for="address"&gt;Address&lt;/label&gt;
    &lt;input class="form-control" type="text" name="address" id="address" autocorrect="off" autocomplete="shipping street-address"&gt;
  &lt;/div&gt;
  &lt;div class="form-group"&gt;
    &lt;label for="frmCityS"&gt;City&lt;/label&gt;
    &lt;input class="form-control" name="ship-city" id="frmCityS" autocomplete="shipping locality"&gt;
  &lt;/div&gt;
  &lt;div class="form-row"&gt;
    &lt;div class="form-group col-lg-8"&gt;
      &lt;label for="frmStateS"&gt;Province&lt;/label&gt;
      &lt;select class="form-control" name="state" id="state"&gt;
        &lt;option value="AB"&gt;Alberta&lt;/option&gt;
        &lt;option value="BC"&gt;British Columbia&lt;/option&gt;
        &lt;option value="MB"&gt;Manitoba&lt;/option&gt;
        &lt;option value="NB"&gt;New Brunswick&lt;/option&gt;
        &lt;option value="NL"&gt;Newfoundland and Labrador&lt;/option&gt;
        &lt;option value="NT"&gt;Northwest Territory&lt;/option&gt;
        &lt;option value="NS"&gt;Nova Scotia&lt;/option&gt;
        &lt;option value="NU"&gt;Nunavut&lt;/option&gt;
        &lt;option value="ON"&gt;Ontario&lt;/option&gt;
        &lt;option value="PE"&gt;Prince Edward Island&lt;/option&gt;
        &lt;option value="QC"&gt;Quebec&lt;/option&gt;
        &lt;option value="SK"&gt;Saskatchewan&lt;/option&gt;
        &lt;option value="YT"&gt;Yukon Territory&lt;/option&gt;
      &lt;/select&gt;
    &lt;/div&gt;
    &lt;div class="form-group col-lg-4"&gt;
      &lt;label for="frmZipS"&gt;Postal Code&lt;/label&gt;
      &lt;input class="form-control" name="ship-zip" id="frmZipS" placeholder="10011" autocomplete="shipping postal-code"&gt;
    &lt;/div&gt;
  &lt;/div&gt;
  &lt;div class="form-group"&gt;
    &lt;button type="submit" class="btn btn-primary"&gt;Do something&lt;/button&gt;
  &lt;/div&gt;
&lt;/form&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Forms with stacked labels should be used when most of the inputs are of the same type and where labels can vary in length. Also best for shorter
            forms. Forms where this patterns is suitable:
          </li>
          <ul>
            <li>Sign in form</li>
            <li>Contact form</li>
            <li>Credit card form</li>
            <li>Settings form</li>
          </ul>
          <li>
            All fields should have a label with a "for" value to focus the input on click. Label should always be above the field
          </li>
          <li>Fields should be same width</li>
          <li>
            If the form is the primary element on the page autofocus the first input
          </li>
          <li>
            Fields can be grouped only if they are related and if grouping them provides a helpful visual cue about the length of the text
          </li>
        </ul>
      </div>
    </div>

    <h3 id="inline">Form with inline labels</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <form>
            <fieldset>
              <legend>Section Title</legend>
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label">Label</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="name" />
                </div>
              </div>
              <div class="form-group row">
                <label for="name2" class="col-sm-3 col-form-label">Label</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="name2" />
                </div>
              </div>
              <div class="form-group row">
                <label for="name3" class="col-sm-3 col-form-label"
                  >Label
                  <a class="text-tertiary collapse-toggle" href="#collapseExample" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">
                    <svg class="icon icon-help">
                      <use xlink:href="/icons/symbol-defs.svg#icon-help"></use>
                    </svg>
                    <svg class="icon icon-close-circle">
                      <use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use>
                    </svg>
                  </a>
                </label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="name3" />
                </div>
                <div class="collapse col" id="collapseExample">
                  <div class="card card-body bg-light mt-2">
                    <h4>This is an example of a help text collapse.</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed
                      consequat. Cras congue elit eget dolor aliquam dignissim.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Section Title</legend>
              <div class="form-group row">
                <label for="fileReference" class="col-sm-3 col-form-label">Reference</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="fileReference" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Pick one</label>
                <div class="col-sm-9">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" />
                    <label class="custom-control-label" for="customRadio1">Option 1</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" />
                    <label class="custom-control-label" for="customRadio2">Option 2</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" />
                    <label class="custom-control-label" for="customRadio3">Option 3</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="fileToUpload" class="col-sm-3 col-form-label">Upload data</label>
                <div class="col-sm-9">
                  <input class="form-control" type="file" name="fileToUpload" id="fileToUpload" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-9 offset-sm-3">
                  <div class="custom-control custom-checkbox">
                    <input id="check" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="check">Remember my preference</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-9 offset-sm-3">
                  <button type="submit" class="btn btn-primary">
                    Do something
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;form&gt;
  &lt;fieldset&gt;
    &lt;legend&gt;Section Title&lt;/legend&gt;
    &lt;div class="form-group row"&gt;
      &lt;label for="name" class="col-sm-3 col-form-label"&gt;Label&lt;/label&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;input type="text" class="form-control" id="name"&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="form-group row"&gt;
      &lt;label for="name" class="col-sm-3 col-form-label"&gt;Label&lt;/label&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;input type="text" class="form-control" id="name"&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="form-group row"&gt;
      &lt;label for="name" class="col-sm-3 col-form-label"&gt;Label
        &lt;a class="text-tertiary collapse-toggle" href="#collapseExample" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample"&gt;
          &lt;svg class="icon icon-help"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-help"&gt;&lt;/use&gt;&lt;/svg&gt;
          &lt;svg class="icon icon-close-circle"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close-circle"&gt;&lt;/use&gt;&lt;/svg&gt;
        &lt;/a&gt;
      &lt;/label&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;input type="text" class="form-control" id="name"&gt;
      &lt;/div&gt;
      &lt;div class="collapse col" id="collapseExample"&gt;
        &lt;div class="card card-body bg-secondary mt-2"&gt;
          &lt;strong&gt;This is an example of a help text collapse.&lt;/strong&gt; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed consequat. Cras congue elit eget dolor aliquam dignissim.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/fieldset&gt;
  &lt;fieldset&gt;
    &lt;legend&gt;Section Title&lt;/legend&gt;
    &lt;div class="form-group row"&gt;
      &lt;label for="fileReference" class="col-sm-3 col-form-label"&gt;Reference&lt;/label&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;input type="text" class="form-control" id="fileReference"&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="form-group row"&gt;
      &lt;label class="col-sm-3 col-form-label"&gt;Pick one&lt;/label&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;div class="custom-control custom-radio"&gt;
          &lt;input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"&gt;
          &lt;label class="custom-control-label" for="customRadio1"&gt;Option 1&lt;/label&gt;
        &lt;/div&gt;
        &lt;div class="custom-control custom-radio"&gt;
          &lt;input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"&gt;
          &lt;label class="custom-control-label" for="customRadio2"&gt;Option 2&lt;/label&gt;
        &lt;/div&gt;
        &lt;div class="custom-control custom-radio"&gt;
          &lt;input type="radio" id="customRadio3" name="customRadio" class="custom-control-input"&gt;
          &lt;label class="custom-control-label" for="customRadio3"&gt;Option 3&lt;/label&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="form-group row"&gt;
      &lt;label for="fileToUpload" class="col-sm-3 col-form-label"&gt;Upload data&lt;/label&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;input class="form-control" type="file" name="fileToUpload" id="fileToUpload"&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="form-group row"&gt;
      &lt;div class="col-sm-9 offset-sm-3"&gt;
        &lt;div class="custom-control custom-checkbox"&gt;
          &lt;input id="check" type="checkbox" class="custom-control-input"&gt;
          &lt;label class="custom-control-label" for="check"&gt;Remember my preference&lt;/label&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="form-group row"&gt;
      &lt;div class="col-sm-9 offset-sm-3"&gt;
        &lt;button type="submit" class="btn btn-primary"&gt;Do something&lt;/button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/fieldset&gt;
&lt;/form&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Forms with inline labels are best for long forms with advanced and mixed data inputs types
          </li>
          <li>
            Labels should be right aligned and as short as possible to reduce eye movement
          </li>
          <li>
            This type of form requires more attention from users and can be tiresome on longer forms. Consider grouping related information in seperate
            fieldsets
          </li>
        </ul>
      </div>
    </div>

    <h3 id="sizes">Form field sizes</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <h4 class="mb-0">XS</h4>
          <ul class="small">
            <li>approx. 1/8 width of panel</li>
            <li>minimum of 5 characters, 9 average</li>
            <li>use cases: Percentage, small numeric values</li>
          </ul>
          <input type="text" class="form-control col-2" value="Lorem ips" />

          <h4 class="mb-0 mt-4">S</h4>
          <ul class="small">
            <li>approx. 1/4 width of panel</li>
            <li>minimum of 14 characters, 25 average</li>
            <li>use cases: Postal code, date,</li>
          </ul>
          <input type="text" class="form-control col-3" value="Lorem ipsum dolor sit ame" />

          <h4 class="mb-0 mt-4">M</h4>
          <ul class="small">
            <li>approx. 1/2 width of panel</li>
            <li>minimum of 32 characters, 60 average</li>
            <li>
              use cases: First name, last name, city, country, phone, email
            </li>
          </ul>
          <input type="text" class="form-control col-6" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed" />

          <h4 class="mb-0 mt-4">L</h4>
          <ul class="small">
            <li>approx. 3/4 width of panel, single row</li>
            <li>minimum of 50 characters, 95 average</li>
            <li>
              use cases: Same application as M or XM depending on panel width and surrounding elements
            </li>
          </ul>
          <input
            type="text"
            class="form-control col-9"
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l"
          />

          <h4 class="mb-0 mt-4">XL</h4>
          <ul class="small">
            <li>full width of panel, single row</li>
            <li>minimum of 68 characters, 130 average</li>
            <li>
              use cases: Full name of person or business, one line descriptions
            </li>
          </ul>
          <input
            type="text"
            class="form-control"
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut"
          />

          <h4 class="mb-0 mt-4">XXL</h4>
          <ul class="small">
            <li>
              multiple rows, as wide as possible given the size of the panel and the position of adjacent labels and fields
            </li>
            <li>use cases: multiline descriptions and instructions</li>
          </ul>
          <textarea class="form-control" cols="30" rows="5"></textarea>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
xs:
&lt;input type="text" class="form-control col-2"&gt;
s:
&lt;input type="text" class="form-control col-3"&gt;
m:
&lt;input type="text" class="form-control col-6"&gt;
l:
&lt;input type="text" class="form-control col-9"&gt;
xl:
&lt;input type="text" class="form-control col-6"&gt;
xxl:
&lt;textarea class="form-control" cols="30" rows="5"&gt;&lt;/textarea&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul></ul>
      </div>
    </div>

    <h3 id="radioGroup">Radio Button Group</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="radio-btn-group">
            <div class="radio-btn">
              <input id="radioGroup1" name="radioGroup" type="radio" class="radio" checked />
              <label for="radioGroup1">
                Radio option 1
              </label>
            </div>
            <div class="radio-btn">
              <input id="radioGroup2" name="radioGroup" type="radio" class="radio" />
              <label for="radioGroup2">
                Radio option 2
              </label>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="radio-btn-group"&gt;
  &lt;div class="radio-btn"&gt;
    &lt;input id="radioGroup1" name="radioGroup" type="radio" class="radio" checked&gt;
    &lt;label for="radioGroup1"&gt;
      Radio option 1
    &lt;/label&gt;
  &lt;/div&gt;
  &lt;div class="radio-btn"&gt;
    &lt;input id="radioGroup2" name="radioGroup" type="radio" class="radio"&gt;
    &lt;label for="radioGroup2"&gt;
      Radio option 2
    &lt;/label&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Used to draw attention to a prominent choice in a form</li>
          <li>Should be limited to two choices</li>
        </ul>
      </div>
    </div>

    <h3 id="textWithAction">Text field with action</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="input-group">
            <input class="form-control" type="text" name="authorizedSignatory1" id="authorizedSignatory1" />
            <div class="input-group-append">
              <button class="btn btn-danger" @click.prevent="authorizedSignatureAdded = false">
                Delete
              </button>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="input-group"&gt;
  &lt;input class="form-control" type="text" name="authorizedSignatory1" id="authorizedSignatory1"&gt;
  &lt;div class="input-group-append"&gt;
    &lt;button class="btn btn-danger" @click.prevent="authorizedSignatureAdded = false"&gt;Delete&lt;/button&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Useful for dynamically created fields that has their own individual action
          </li>
        </ul>
      </div>
    </div>

    <h3 id="stepper">Step Input</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <div class="input-group input-group-step">
              <div class="input-group-prepend">
                <button class="btn btn-secondary" @click="stepper--">
                  <strong>-</strong>
                </button>
              </div>
              <input type="text" class="form-control" id="exampleInputAmount" placeholder="Amount" v-bind:value="stepper" />
              <div class="input-group-append">
                <button class="btn btn-secondary" @click="stepper++">
                  <strong>+</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;div class="input-group"&gt;
    &lt;div class="input-group-prepend"&gt;
      &lt;button class="btn btn-outline-secondary"&gt;-&lt;/button&gt;
    &lt;/div&gt;
    &lt;input type="text" class="form-control" id="exampleInputAmount" placeholder="Amount" v-bind:value="stepper"&gt;
    &lt;div class="input-group-append"&gt;
      &lt;button class="btn btn-outline-secondary"&gt;+&lt;/button&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li></li>
        </ul>
      </div>
    </div>

    <h3 id="search-category">Categorized Search</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <select class="custom-select" id="exampleFormControlSelect2">
                  <option>Select a category</option>
                  <option>Category 1</option>
                  <option>Category 2</option>
                  <option>Category 3</option>
                  <option>Category 4</option>
                  <option>Category 5</option>
                </select>
              </div>
              <input type="text" class="form-control" placeholder="search" />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                  <svg class="icon icon-search">
                    <use xlink:href="/icons/symbol-defs.svg#icon-search"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;div class="input-group"&gt;
    &lt;div class="input-group-prepend"&gt;
      &lt;select class="custom-select" id="exampleFormControlSelect2"&gt;
        &lt;option&gt;Select a category&lt;/option&gt;
        &lt;option&gt;Category 1&lt;/option&gt;
        &lt;option&gt;Category 2&lt;/option&gt;
        &lt;option&gt;Category 3&lt;/option&gt;
        &lt;option&gt;Category 4&lt;/option&gt;
        &lt;option&gt;Category 5&lt;/option&gt;
      &lt;/select&gt;
    &lt;/div&gt;
    &lt;input type="text" class="form-control" placeholder="search"&gt;
    &lt;div class="input-group-append"&gt;
      &lt;button class="btn btn-primary" type="button"&gt;
        &lt;svg class="icon icon-search"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-search"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;/button&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul></ul>
      </div>
    </div>

    <h3 id="switch">Switch</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <span class="switch">
              <input type="checkbox" class="switch" id="switch-normal" />
              <label for="switch-normal">Switch off/on</label>
            </span>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;span class="switch"&gt;
    &lt;input type="checkbox" class="switch" id="switch-normal"&gt;
    &lt;label for="switch-normal"&gt;Switch off/on&lt;/label&gt;
  &lt;/span&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Use a switch to toggle a feature on and off</li>
          <li>
            Should not be used for "opt-in" or "acknowledge" scenarios where a regular checkbox would be a better option
          </li>
        </ul>
      </div>
    </div>

    <h3 id="datepicker">Datepicker</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group col-md-8 px-0">
            <label for="">Single date</label>
            <div class="input-group">
              <svg class="icon icon-inline-right icon-date position-absolute">
                <use xlink:href="/icons/symbol-defs.svg#icon-date"></use>
              </svg>
              <input type="text" class="form-control" @focus="datepicker = !datepicker" @blur="datepicker = !datepicker" value="2019-06-14" />
              <DatePicker v-if="datepicker"></DatePicker>
            </div>
          </div>

          <div class="form-group col-md-8 px-0">
            <label for="">Date range</label>
            <div class="input-group">
              <svg class="icon icon-inline-right icon-date position-absolute">
                <use xlink:href="/icons/symbol-defs.svg#icon-date"></use>
              </svg>
              <input
                type="text"
                class="form-control"
                @focus="datepickerRange = !datepickerRange"
                @blur="datepickerRange = !datepickerRange"
                value="2019-06-14 - 2019-06-29"
              />
              <DatePickerRange v-if="datepickerRange"></DatePickerRange>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group col-md-8 px-0"&gt;
  &lt;label for=""&gt;From&lt;/label&gt;
  &lt;div class="input-group"&gt;
    &lt;svg class="icon icon-inline-right icon-date position-absolute"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-date"&gt;&lt;/use&gt;&lt;/svg&gt;
    &lt;input type="text" class="form-control"&gt;
    &lt;DatePicker&gt;&lt;/DatePicker&gt;
  &lt;/div&gt;
&lt;/div&gt;

&lt;div class="form-group col-md-8 px-0"&gt;
  &lt;label for=""&gt;Only button triggers popup&lt;/label&gt;
  &lt;div class="input-group"&gt;
    &lt;input class="form-control"&gt;
    &lt;div class="input-group-append"&gt;
      &lt;button class="btn btn-secondary calendar px-2" type="button"&gt;
        &lt;svg class="icon icon-date"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-date"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;/button&gt;
    &lt;/div&gt;
    &lt;DatePicker&gt;&lt;/DatePicker&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            If the whole field triggers the popup, the calendar icon is used as an indication that the field has a datepicker.
          </li>
        </ul>
      </div>
    </div>

    <h3 id="upload">File Upload</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <label for="fileToUpload">Upload data</label>
            <div :class="['input-group', fileIsLoading ? 'is-loading-inline' : '']">
              <input class="form-control" type="file" name="fileToUpload" id="fileToUpload" @change="upload" ref="uploadField" />
            </div>
          </div>
          <table class="table table-hover table-stacked table-select-row">
            <tbody>
              <tr v-for="(file, index) in files" :key="index">
                <td class="pl-0" data-header="Name">
                  <a href="javascript:void(0)">{{ file.name }}</a>
                </td>
                <td data-header="Date">
                  {{ new Date(file.lastModified).toLocaleString("en-US", options) }}
                </td>
                <td data-header="Actions" class="text-right pr-0">
                  <button class="btn btn-link btn-sm p-0 text-danger" @click="removeFile(file)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;label for="fileToUpload"&gt;Upload data&lt;/label&gt;
  &lt;div class="input-group is-loading-inline"&gt;
    &lt;input class="form-control" type="file" name="fileToUpload" id="fileToUpload"&gt;
  &lt;/div&gt;
&lt;/div&gt;
&lt;table class="table table-hover table-stacked table-select-row"&gt;
  &lt;tbody&gt;
    &lt;tr&gt;
      &lt;td data-header="Name"&gt;
        &lt;a href="javascript:void(0)"&gt;Document1.pdf&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Date"&gt;Mar 10, 2018 5:07 PM&lt;/td&gt;
      &lt;td data-header="Actions" class="text-right"&gt;
        &lt;a class="text-danger" href="javaScript:void(0)"&gt;Delete&lt;/a&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td data-header="Name"&gt;
        &lt;a href="javascript:void(0)"&gt;Document2.pdf&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Date"&gt;Mar 12, 2018 3:15 PM&lt;/td&gt;
      &lt;td data-header="Actions" class="text-right"&gt;
        &lt;a class="text-danger" href="javaScript:void(0)"&gt;Delete&lt;/a&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
  &lt;/tbody&gt;
&lt;/table&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Toggle the is-loading-inline class on the .input-group tag when file file is uploading
          </li>
        </ul>
      </div>
    </div>

    <h3 id="dragDrop">File Upload - Drag and Drop</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div
            v-cloak
            @drop.prevent="addFileDnd"
            @dragover.prevent
            :class="['drop', dragging ? 'droppable' : '']"
            @dragenter="dragging = true"
            @dragleave="dragging = false"
          >
            <div v-if="!fileDndIsLoading">
              <label class="drop-label text-center">
                <strong>Drag PDF files to upload</strong><br />or
                <button class="btn btn-secondary d-block mt-2">
                  Choose PDF Files
                </button>
              </label>
              <input type="file" class="dropzone" :multiple="true" id="uploader" @change="uploadDnd" />
            </div>
            <div class="is-loading-inline" v-if="fileDndIsLoading">
              File uploading
            </div>
          </div>
          <table class="table table-hover table-stacked table-select-row">
            <tbody>
              <tr v-for="(file, index) in filesDnd" :key="index">
                <td class="pl-0" data-header="Name">
                  <a href="javascript:void(0)">{{ file.name }}</a>
                </td>
                <td data-header="Date">
                  {{ new Date(file.lastModified).toLocaleString("en-US", options) }}
                </td>
                <td data-header="Actions" class="text-right pr-0">
                  <button class="btn btn-link btn-sm p-0 text-danger" @click="removeFileDnd(file)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="drop"&gt;
  // Render conditionally when file is not uploading
  &lt;div&gt;
    &lt;label class="drop-label text-center"&gt;
      &lt;strong&gt;Drag PDF files to upload&lt;/strong&gt;&lt;br&gt;or
      &lt;button class="btn btn-secondary d-block mt-2"&gt;
        Choose PDF Files
      &lt;/button&gt;
    &lt;/label&gt;
    &lt;input type="file"
          class="dropzone"
          accept=""
          :multiple="true"
          id="uploader"&gt;
  &lt;/div&gt;
  // Render conditionally when file is uploading
  &lt;div class="is-loading-inline"&gt;File uploading&lt;/div&gt;
&lt;/div&gt;
&lt;table class="table table-hover table-stacked table-select-row"&gt;
  &lt;tbody&gt;
    &lt;tr&gt;
      &lt;td data-header="Name"&gt;
        &lt;a href="javascript:void(0)"&gt;Document1.pdf&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Date"&gt;Mar 10, 2018 5:07 PM&lt;/td&gt;
      &lt;td data-header="Actions" class="text-right"&gt;
        &lt;a class="text-danger" href="javaScript:void(0)"&gt;Delete&lt;/a&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td data-header="Name"&gt;
        &lt;a href="javascript:void(0)"&gt;Document2.pdf&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Date"&gt;Mar 12, 2018 3:15 PM&lt;/td&gt;
      &lt;td data-header="Actions" class="text-right"&gt;
        &lt;a class="text-danger" href="javaScript:void(0)"&gt;Delete&lt;/a&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
  &lt;/tbody&gt;
&lt;/table&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            When files are uploading the field label and button should be replaced with an inline loading indicator so the user gets visual feedback on the
            upload action
          </li>
        </ul>
      </div>
    </div>

    <h3 id="states">Form States</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group is-valid">
            <label class="control-label" for="inputSuccess1">Input with success</label>
            <input type="text" class="form-control is-valid" id="inputSuccess1" />
          </div>
          <div class="form-group is-invalid">
            <label class="control-label" for="inputDanger1">Input with danger</label>
            <input type="text" class="form-control is-invalid" id="inputDanger1" />
            <div class="invalid-feedback">
              Help text explaining the field error
            </div>
          </div>
          <div class="form-group is-invalid">
            <div class="custom-control custom-checkbox">
              <input id="checkInvalid" type="checkbox" class="custom-control-input" />
              <label for="checkInvalid" class="custom-control-label">Remember my preference</label>
            </div>
            <div class="invalid-feedback">This checkbox must be checked</div>
          </div>
          <div class="form-group is-invalid">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" />
              <label class="custom-control-label" for="customRadioInline1">This</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" />
              <label class="custom-control-label" for="customRadioInline2">Or this</label>
            </div>
            <div class="invalid-feedback">Please choose an option</div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group is-valid"&gt;
  &lt;label class="control-label" for="inputSuccess1"&gt;Input with success&lt;/label&gt;
  &lt;input type="text" class="form-control is-valid" id="inputSuccess1"&gt;
&lt;/div&gt;
&lt;div class="form-group is-invalid"&gt;
  &lt;label class="control-label" for="inputDanger1"&gt;Input with danger&lt;/label&gt;
  &lt;input type="text" class="form-control is-invalid" id="inputDanger1"&gt;
  &lt;div class="invalid-feedback"&gt;Help text explaining the field error&lt;/div&gt;
&lt;/div&gt;
&lt;div class="form-group is-invalid"&gt;
  &lt;div class="custom-control custom-checkbox"&gt;
    &lt;input id="checkInvalid" type="checkbox" class="custom-control-input"&gt;
    &lt;label for="checkInvalid" class="custom-control-label"&gt;Remember my preference&lt;/label&gt;
  &lt;/div&gt;
  &lt;div class="invalid-feedback"&gt;This checkbox must be checked&lt;/div&gt;
&lt;/div&gt;
&lt;div class="form-group is-invalid"&gt;
  &lt;div class="custom-control custom-radio custom-control-inline"&gt;
    &lt;input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input"&gt;
    &lt;label class="custom-control-label" for="customRadioInline1"&gt;This&lt;/label&gt;
  &lt;/div&gt;
  &lt;div class="custom-control custom-radio custom-control-inline"&gt;
    &lt;input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input"&gt;
    &lt;label class="custom-control-label" for="customRadioInline2"&gt;Or this&lt;/label&gt;
  &lt;/div&gt;
  &lt;div class="invalid-feedback"&gt;Please choose an option&lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Fields should be validated when the field loses focus and not while the user is still typing
          </li>
          <li>
            Errors should have clear help text explaning what the error is and how to fix it
          </li>
          <li>
            If there are still errors when pressing submit, the form should scroll up to the first error on the form and focus the field
          </li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "forms",

  data() {
    return {
      title: "Forms",
      stepper: 0,
      datepicker: false,
      datepickerRange: false,
      dragging: false,
      files: [],
      filesDnd: [],
      fileIsLoading: false,
      fileDndIsLoading: false,
      options: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    };
  },

  computed: {
    uploadDisabled() {
      return this.filesDnd.length === 0;
    },
  },

  methods: {
    addFileDnd(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      this.fileDndIsLoading = true;
      setTimeout(() => {
        [...droppedFiles].forEach((f) => {
          this.filesDnd.push(f);
        });
        this.fileDndIsLoading = false;
        this.dragging = false;
      }, 2000);
    },
    upload(e) {
      let droppedFiles = e.target.files;
      if (!droppedFiles) return;
      this.fileIsLoading = true;
      setTimeout(() => {
        [...droppedFiles].forEach((f) => {
          this.files.push(f);
        });
        this.fileIsLoading = false;
        this.$refs["uploadField"].value = "";
      }, 2000);
    },
    uploadDnd(e) {
      let droppedFiles = e.target.files;
      if (!droppedFiles) return;
      this.fileDndIsLoading = true;
      setTimeout(() => {
        [...droppedFiles].forEach((f) => {
          this.filesDnd.push(f);
        });
        this.fileDndIsLoading = false;
        this.dragging = false;
      }, 2000);
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f != file;
      });
    },
    removeFileDnd(file) {
      this.filesDnd = this.filesDnd.filter((f) => {
        return f != file;
      });
    },
  },
};
</script>
